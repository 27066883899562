<template>
  <div class="faq-content">
    <ul class="faq-content__dropdown-box">
      <li
        v-for="(tab, index) in items"
        :key="index"
        class="faq-content__dropdown-box__item"
        :class="{ 'faq-content__dropdown-box__item--active': active === index }"
      >
        <div
          role="button"
          tabindex="0"
          class="flex justify-between cursor-pointer"
          @keypress="openItem(index)"
          @click="openItem(index)"
        >
          <h2
            class="font-sans font-bold select-none m-0 mr-10 flex items-center faq-content__dropdown-box__item__title text-sm md:text-base"
          >
            {{ tab.title }}
          </h2>
          <img
            class="mx-2 faq-content__dropdown-box__item__chevron-down"
            :class="{
              'faq-content__dropdown-box__item--active__chevron-down':
                active === index,
            }"
            :src="`${$paths.assetsUrl}img/static-pages/chevron-down.svg`"
            alt="chevron"
          />
        </div>
        <div ref="item" class="faq-content__dropdown-box__item__content">
          <div class="pt-2 pb-6">
            <div class="leading-relative" v-html="tab.content" />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      active: null,
    };
  },
  mounted() {
    this.active = this.items.length - 1;
    const activeItem = this.$refs.item[this.active];
    if (activeItem) {
      const firstDiv = activeItem.querySelector('div');
      if (firstDiv) {
        setTimeout(() => {
          activeItem.style.height = `${firstDiv.clientHeight}px`;
        }, 100);
      }
    }
  },
  methods: {
    openItem(index) {
      if (index !== this.active) {
        setTimeout(() => {
          window.scrollTo({
            behavior: 'smooth',
            top: this.$refs.item[index].offsetTop - 150,
          });
        }, 250);
      }
      if (
        this.active === index &&
        this.$refs.item[this.active].style.height !== 0
      ) {
        this.$refs.item[this.active].style.height = 0;
        this.active = -1;
      } else {
        if (this.active > -1) {
          this.$refs.item[this.active].style.height = 0;
        }
        this.active = index;
        this.$refs.item[index].style.height = `${
          this.$refs.item[index].getElementsByTagName('div')[0].clientHeight
        }px`;
      }
    },
  },
};
</script>
<style lang="scss">
.faq-content {

  &__dropdown-box {
    &__item {
      $self: &;
      &--active {
        &__chevron-down {
          transform: rotate(180deg);
        }
        #{$self}__content {
          @apply h-auto;
        }
      }

      &__title {
        @apply text-md md:text-base;
        min-height: 3.5rem;
      }

      &__chevron-down {
        transition: 0.3s ease-in-out;
        width: 24px;
      }

      &__content {
        overflow: hidden;
        transition: 0.3s ease-in-out;
        height: 0;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          @apply text-md md:text-base;
        }

        a {
          @apply text-blue-500 hover:underline;
        }

        ul {
          @apply pl-[20px] m-[20px] mr-0 list-disc list-outside;
        }

        p {
          @apply my-[16px] text-md md:text-base;
        }

        .link-button {
          @apply m-[20px];

          a {
            @apply w-full block p-3 rounded bg-orange-500 hover:bg-orange-600 text-white font-semibold hover:no-underline text-center;
          }
        }
      }

      @apply border-t border-gray-200;
    }

    @apply border-b border-gray-200;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"airport-page"},[_c('experiment-toggle',{attrs:{"name":"reduce-font-size"},scopedSlots:_vm._u([{key:"a",fn:function(){return undefined},proxy:true},{key:"b",fn:function(){return [_c('ReduceFontSize')]},proxy:true}])}),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-idle":""}},[_c('Highlight')],1),_vm._v(" "),_c('div',{staticClass:"airport-page__breadcrumbs"},[_c('LazyHydrate',{attrs:{"never":""}},[(_vm.navigation.length && _vm.navigation.length)?_c('BreadCrumbs',{attrs:{"navigation":_vm.navigation}}):_vm._e()],1)],1),_vm._v(" "),_c('experiment-toggle',{attrs:{"name":"airSpringCampaign2"},scopedSlots:_vm._u([{key:"a",fn:function(){return undefined},proxy:true},{key:"b",fn:function(){return [_c('SpringBanner')]},proxy:true},{key:"c",fn:function(){return [_c('SpringCornerButton')]},proxy:true}])}),_vm._v(" "),_c('experiment-toggle',{attrs:{"name":"airSpringCampaignNL"},scopedSlots:_vm._u([{key:"a",fn:function(){return undefined},proxy:true},{key:"b",fn:function(){return [_c('NlSpringBanner')]},proxy:true},{key:"c",fn:function(){return [_c('NlSpringCornerButton')]},proxy:true}])}),_vm._v(" "),(_vm.hasParkings && _vm.be604ukQuickWinsRunning)?_c('div',{class:[
      'airport-page__parking-list',
      { 'airport-page__parking-list--no-reviews': !_vm.hasReviews },
    ]},[_c('div',{staticClass:"airport-page__merchant-list"},[_c('LazyHydrate',{attrs:{"when-idle":""}},[_c('Merchants')],1)],1)]):_vm._e(),_vm._v(" "),_c('div',[(!_vm.be604ukQuickWinsRunning)?[_c('LazyHydrate',{attrs:{"when-idle":""}},[_c('UpToDateInformationV2',{staticClass:"xs:container px-5 md:px-10"})],1)]:_c('LazyHydrate',{attrs:{"never":""}},[_c('be604UpToDateInformation')],1)],2),_vm._v(" "),_c('div',{class:[
      'airport-page__content',
      { 'airport-page__content--no-reviews': !_vm.hasReviews },
    ]},[(!_vm.be604ukQuickWinsRunning)?_c('div',{ref:"content",staticClass:"airport-page__content-data",domProps:{"innerHTML":_vm._s(_vm.airportContent)}}):_c('be604Content',{attrs:{"airport-content":_vm.airportContent}})],1),_vm._v(" "),_c('div',[_c('LazyHydrate',{attrs:{"when-idle":""}},[_c('PricingTable',{staticClass:"pricing_table_container",attrs:{"slug":_vm.airport.slug}})],1)],1),_vm._v(" "),_c('div',{staticClass:"airport-page__parkos_bg"},[(_vm.hasReviews)?_c('div',{staticClass:"airport-page__reviews-section"},[_c('LazyHydrate',{attrs:{"when-idle":""}},[_c('Reviews',{staticClass:"airport-page__reviews-section__carousel"})],1)],1):_vm._e(),_vm._v(" "),(_vm.hasParkings && !_vm.be604ukQuickWinsRunning)?_c('div',{class:[
        'airport-page__parking-list',
        { 'airport-page__parking-list--no-reviews': !_vm.hasReviews },
      ]},[_c('div',{staticClass:"airport-page__merchant-list"},[_c('LazyHydrate',{attrs:{"when-idle":""}},[_c('Merchants')],1)],1)]):_vm._e(),_vm._v(" "),(_vm.$store.state.faq && _vm.$store.state.faq.length)?_c('div',{staticClass:"airport-page__faq-container"},[(!_vm.be604ukQuickWinsRunning)?_c('LazyHydrate',{attrs:{"when-idle":""}},[_c('Faq',{staticClass:"py-10 md:py-20"})],1):_c('LazyHydrate',{attrs:{"when-idle":""}},[_c('be604Faq',{staticClass:"py-10 md:py-20"})],1)],1):_vm._e(),_vm._v(" "),_c('experiment-toggle',{attrs:{"name":"internal-links-structure"},scopedSlots:_vm._u([{key:"a",fn:function(){return undefined},proxy:true},{key:"b",fn:function(){return [_c('div',{staticClass:"bg-white"},[_c('InternalLinks')],1)]},proxy:true}])})],1),_vm._v(" "),(!_vm.be604ukQuickWinsRunning)?_c('div',{staticClass:"border-t border-[#e5e7eb]",attrs:{"id":"airports"}},[_c('div',{staticClass:"airport-page__usps-container"},[_c('LazyHydrate',{attrs:{"never":""}},[_c('Usps',{staticClass:"py-10 md:py-20"})],1)],1)]):_vm._e(),_vm._v(" "),_c('LazyHydrate',{attrs:{"never":"","trigger-hydration":_vm.showZendesk && !_vm.isMobile}},[_c('HelpButton',{attrs:{"language":_vm.language.lang}})],1),_vm._v(" "),_c('client-only',[_c('noscript',[(_vm.cheqDomains[_vm.$paths.host])?_c('iframe',{staticStyle:{"display":"none"},attrs:{"src":_vm.cheqDomains[_vm.$paths.host]?.nojs,"width":"0","height":"0"}}):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }